<div class="master-option-bar-root-container" [style.width]="show ? 'var(--left-side-optionbar-width)' : '25px'">
    <div class="menu-list-container">
        <!-- Masters List -->
        <div class="section-container">
            <div class="content item-content">


                <mat-list-item class="mat-list-item" [routerLink]="['/reward']" 
                [queryParams]="{ tp: '2' }" [routerLinkActive]="['is-active']"
                    [matTooltip]="!show ? 'Reward' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip" (click)="resetState()">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/reward-category.svg">
                        <span class="master-title">Reward</span>
                    </div>
                    
            
                </mat-list-item>
               
                <mat-list-item class="mat-list-item" [routerLink]="['/offer/']" 
                [queryParams]="{ tp: '1' }" [routerLinkActive]="['is-active']"
                    [matTooltip]="!show ? 'Offer' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip" (click)="resetState()">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/promotion.svg">
                        <span class="master-title">Offer</span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/reward-category" [routerLinkActive]="['is-active']"
                [matTooltip]="!show ? 'Category' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip" (click)="resetState()">
                <div class="mat-list-item-contents">
                    <img src="assets/images/menu/svg/promotion-category.svg">
                    <span class="master-title">Category</span>
                </div>
            </mat-list-item>


             

               
            </div>
        </div>
    </div>
</div>