import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  BillDiscountAmountModel,
  BillDiscountItemModel,
  SaleItemDiscountModel,
  BuyXGetYDicountModel as BuyXGetYDicountModel,
  LuckyDrawModel,
  CustomerFilter,
  SaleChannels, AccessMode
} from "../model/lucky-draw.model";
import { LuckyDrawService } from "../lucky-draw.service";
import { SnackBarData } from "src/app/common/components/snackbar/model/snackbar.model";
import { SnackBarService } from "src/app/common/components/snackbar/snackbar.service";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { DirtyCheck } from "src/app/common/guards/form-deactivate.guard";
import { SegmentService } from "../../segment/segment.service";
import { RewardCategoryService } from "../../reward-category/reward-category.service";
import { LuckyDrawSegmentEditDialogComponent } from "../dialogs/luckydraw-segment-edit-dialog/luckydraw-segment-edit-dialog.component";
import { LuckyDrawProductBrowseDialogComponent } from "../dialogs/luckydraw-product-browse-dialog/luckydraw-product-browse-dialog.component";
import { LuckyDrawProductItemsDialogComponent } from "../dialogs/luckydraw-product-items-dialog/luckydraw-product-items-dialog.component";
import { DiscountModel } from "../../discount/model/discount.model";
import { DatePipe } from "@angular/common";
import { forkJoin } from "rxjs";
import { LuckyDrawImageUploadComponent } from "../dialogs/image-upload/image-upload.component";
import { environment } from "src/environments/environment";
import { getMatIconFailedToSanitizeLiteralError } from "@angular/material/icon";
import { CustomerService } from "../../customer/customer.service";
import { LuckyDrawCustFilterDialogComponent } from "../dialogs/luckdraw-cust-filter-dialog/luckydraw-cust-filter-dialog.component";
import { trigger } from "@angular/animations";
import { LuckyDrawProductsDialogComponent } from "../dialogs/luckydraw-products-dialog/luckydraw-products-dialog.component";
import { formatDate } from '@angular/common';
import { ConfirmDialogComponent, ConfirmDialogModel } from "src/app/common/components/confirm-dialog/confirm-dialog.component";
import { SystemSettingsService } from "src/app/common/services/system-settings.service";
import moment from 'moment';
import { RewardDialogComponent } from "../components/reward-dialog/reward-dialog.component";
import { PermissionService } from 'src/app/auth/permission.service';

import Quill from 'quill';
import QuillResizeModule from '@botom/quill-resize-module';
import { CustomOption } from "ngx-quill";
import { ColumnFilter, RequestData, SearchSort } from "src/app/common/models/request-data.model";
import { FilterListItemModel, SearchParam, SearchFilterComponent } from 'src/app/common/components/search-filter/search-filter.component';
import { MatSort, SortDirection } from "@angular/material/sort";
Quill.register("modules/resize", QuillResizeModule);

@Component({
    selector: "app-luckydraw-edit",
    templateUrl: "./luckydraw-edit.component.html",
    styleUrls: ["./luckydraw-edit.component.scss"],
    providers: [
        DatePipe, // Add DatePipe to providers
    ],
    animations: [
        trigger("if", [
        // ... animation states and styles
        ]),
    ],
    standalone: false
})
export class LuckyDrawEditComponent implements DirtyCheck, OnInit {
  static readonly CUSTOMER_ELIGIBILITY_ALL = 1;
  static readonly CUSTOMER_ELIGIBILITY_FILTER = 2;

  title: string = "New ";
  editForm!: FormGroup;
  currentDate: Date = new Date(); // Initialize it with the current date.
  isInsertMode: boolean = true; // Set this to false when updating a record.
  isAutoDistribute: boolean = false;
  value!: number;
  activeTab: number = 0;
  radioButtonValue!: string;
  duplicateItems: any[] = [];
  @ViewChild("fileInput") fileInput!: ElementRef;

  luckyDrawInfoModel: LuckyDrawModel = {
    id: 0,
    code: "",
    name: "",
    description: "",
    crm_lucky_draw_hdr_id: undefined,
    date_from: undefined,
    date_to: undefined,
    prize_settings: {
      crm_reward_id:0,
      count:0
    },
    num_winner :0,
    is_deleted: 0,
    is_published: 0,
  };

 
  publishOn!: Date;
  endDate!: Date;
  selectedValidityOption!: string;



  isFormDirty: boolean = false;
  discountList: any;
  segmentList: any;
  rewardCategoryList: any[] = [];
  selectedEventOption: string = "1";
  mainDrawList: any;
  productList: any;
  itemCategoryList: any;
  isDropdownOpen: boolean = false;
  assetsUrl: string;
  refreshTime: any;
  systemEvent: any;
  selectedSystemEvent: any;
  status: any;
  isStatusZero!: boolean;
  isEmptyStatus!: boolean;
  isStatusNotOneOrTwo: boolean = true; // Default value, adjust as needed
  isNewRecord!: boolean;
  filteredCategoryList: any;
  showRadioBtns!: boolean;
  enabledRewards!: number[];
  dateFormat: string;
  allowPublishManually: boolean = false;
  channelSelected: { title: string; value: number; } | undefined;
  displayTitle: any;
  editorTcData: any;
  editorPrivacyData: any;
  editorData: any;
  permission: any;
  selectedItems: any[] = [];
  date_from:any;
  date_to:any;

  customOptions: CustomOption[] = [{
    import: 'attributors/style/align',
    whitelist: ['left', 'center', 'right']
    },
  ];

  searchColumFilter: ColumnFilter[]=[];

  selectedRewardFilter: undefined;

  columnSortData: SearchSort[] = [];
  rewardList: any[]=[];
  prizeSettings:any;
  minimumCount: boolean=false;

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  private uploadedFileName: string = "";

  constructor(
    private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    public luckDrawService: LuckyDrawService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    private segmentService: SegmentService,
    private rewardCategoryService: RewardCategoryService,
    private customerService: CustomerService,
    private datePipe: DatePipe,
    private systemSettingService: SystemSettingsService,
    private PermissionService: PermissionService

  ) {
    this.assetsUrl = environment.assetsUrl;
    this.dateFormat = systemSettingService.getDefaultDateFormat();
    this.permission = PermissionService.getPagePermission('draw');
    this.getRewardList();

  }


  ngOnInit(): void {
    this.loadMainDraws();
    this.loadSystemEvents();
    let rewardId = this.route.snapshot.paramMap.get("id");
    this.editForm = this.builder.group({
      code: [
        this.luckyDrawInfoModel.code,
        [Validators.required, Validators.maxLength(50)],
      ],
      name: [this.luckyDrawInfoModel.name, [Validators.required]],
      description: [this.luckyDrawInfoModel.description,[]],
      crm_lucky_draw_hdr_id: [
        this.luckyDrawInfoModel.crm_lucky_draw_hdr_id,
        [Validators.required],
      ],
    });

    this.editForm.valueChanges.subscribe(() => {
      // this.isFormDirty = true;
      
      //this.isFormDirty = !this.editForm.pristine
      if (this.status !== 0){
        this.isFormDirty = false;

      }else{
        this.isFormDirty = !this.editForm.pristine
      }
    });

    if (rewardId !== null && parseInt(rewardId) !== 0) {
      this.loadSavedLuckDraw(rewardId);
    }
  }

  @ViewChild(MatSort) sort!: MatSort;
  /**
  * Sets the title
  * @returns the title based on mode new/editing
  */
  getTitle(): string {
    let title =
      this.luckyDrawInfoModel.id == 0
        ? "New Draw"
        : "Editing: " + this.luckyDrawInfoModel.name;
    return title;
  }

  /**
  * Sets the sort column
  * @param column 
  * @param direction 
  */
  setSortColumn(column: string, direction: SortDirection): void {
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: column, order: direction });
    this.sort.sort({
      id: column,
      start: direction,
      disableClear: false,
    });

  }

  ortData($event: any): void {
    var sd = this.sort;
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: sd.active, order: sd.direction });
    // this.loadData();
  }

  /**
   * Date should be equal or greater than current date
   * @returns 
   */
  isDateInFuture() {
    return this.publishOn >= this.currentDate;
  }

  isValidEndDate(){
    return this.endDate >= this.publishOn;
  }



  isDirty(): boolean {
    return (this.permission.can_edit && this.isFormDirty);
  }

  // Format a date using DatePipe
  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy') || '';
  }

  /**
   * Returns the display label for segment
   */
  getSegmentLabel(id: number): string {
    let segment = this.segmentList.find((s: any) => s.id == id);
    return segment.code;
  }

  navBack(): void {
    this.router.navigate(["/draw"]);
  }

  publishLuckyDraw(rewardId: any): void {
    let response = this.luckDrawService.publishDraw(rewardId).subscribe({
      next: (response) => {
        if (response.status == "SUCCESS") {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Success",
            type: "success",
          };
          this.snackBarService.openSnackBar(snackBarData);
          this.allowPublishManually = false;
          this.isFormDirty = false;
          this.router.navigate(['lucky-draw']);

          // Set isStatusNotOneOrTwo based on your condition
        } else if (response.status == "NO_DATA_FOUND") {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);

        }
        else {
          let snackBarData: SnackBarData = {
            message: 'This lucky draw can not be published now.',
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (_error) => {
        let snackBarData: SnackBarData = {
          message: "Failed to publish. Connection to the server failed",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }
  /**
   * Load the reaward info from api and set to form
   *
   * @param rewardId
   */

  loadSavedLuckDraw(rewardId: any): void {
    let response = this.luckDrawService.getLuckyDrawById(rewardId).subscribe({
      next: (response) => {
        if (response.status == "SUCCESS") {
          response = response.data.ldHdr
          // this.status = response.data.reward[0].status;
          this.status = response[0].is_published;
          //temporarly commented
          // this.allowPublishManually = response.data.allow_publish_manually;
          this.allowPublishManually = true;
          this.isStatusZero = this.status === 0; // Track if status is zero

          this.buildLuckDrawFromResponse(response);

          // Show notification if status is not '0'
          if (this.status !== 0) {
            const notificationElement = document.getElementById("notification");
            if (notificationElement) {
              notificationElement.style.display = "block";
            }
          }

          // Set isStatusNotOneOrTwo based on your condition
          this.isStatusNotOneOrTwo = this.status !== 1 && this.status !== 2;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message:
            "Failed to get data from server. Connection to the server failed.",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }

getRewardList(){
  let requestData: RequestData = {
    "search": {
      "filters": {
        "simpleSearch": [], "advSearch": [
          { "column": "crm_event_id", "operator": "=", "value": "4" },
          { "column": "apply_customer_filter", "operator": "=", "value": "2" }, { "column": "reward_eligibility", "operator": "=", "value": "2" }, { "column": "status", "operator": "in", "value": ["1"], "type": "list" }], "scope": { "limit": 9999.99, "offset": 0 }
      }, "sort": [{ "column": "name", "order": "asc" }]
    }
  };
  this.luckDrawService.getRewards(requestData).subscribe({
      next: (response) => {
        if (response.status === "SUCCESS") {
          this.rewardList = response.data.rewards;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: "Failed to load reward lists.",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
}

  /**
   * Set the variable from response
   * @param response
   */
  buildLuckDrawFromResponse(response: any): void {
    let luckyDrawInfo = response[0];
    this.luckyDrawInfoModel.id = luckyDrawInfo.id;
    this.luckyDrawInfoModel.code = luckyDrawInfo.code;
    this.luckyDrawInfoModel.name = luckyDrawInfo.name;
    this.luckyDrawInfoModel.description = luckyDrawInfo.description;
    this.date_from = luckyDrawInfo.date_from;
    this.date_to = luckyDrawInfo.date_to;
    if (luckyDrawInfo.prize_settings != null && luckyDrawInfo.prize_settings !== undefined) {
      this.selectedItems = JSON.parse(luckyDrawInfo.prize_settings);
    }
    this.luckyDrawInfoModel.prize_settings = JSON.parse(luckyDrawInfo.prize_settings);
    this.luckyDrawInfoModel.crm_lucky_draw_hdr_id = luckyDrawInfo.crm_lucky_draw_hdr_id;
    this.luckyDrawInfoModel.is_published = luckyDrawInfo.is_published;
    this.editForm.patchValue(this.luckyDrawInfoModel);
    this.isFormDirty = false;
  }

  getRewardName(id: number): any {
    let item = this.rewardList.find((item: any) => item.id == id);
    return item!== undefined ? item.name : null;
  }

  save(): void {
    // console.log(this.editForm.value)
    if (!this.editForm.valid) {
      this.editForm.markAllAsTouched();
      let snackBarData: SnackBarData = {
        message: "Some of fields are empty or conatains invalid data.",
        title: "Failed!!!",
        type: "error",
      };
      this.snackBarService.openSnackBar(snackBarData);
      return;
    }

    if (this.selectedItems.length == 0) {
      let snackBarData: SnackBarData = {
        message: "Please choose at least one reward.",
        title: "Failed!!!",
        type: "error",
      };
      this.snackBarService.openSnackBar(snackBarData);
      return;
    }

    this.minimumCount = this.selectedItems.some(reward => reward.count < 1);
    if(this.minimumCount){
      let snackBarData: SnackBarData = {
        message: "Please set a valid winner count for each reward. Count should be at least 1.",
        title: "Failed!!!",
        type: "error",
      };
      this.snackBarService.openSnackBar(snackBarData);
      return;
    }

    if (!this.date_from) {
      let snackBarData: SnackBarData = {
        message: "Please choose a start date.",
        title: "Failed!!!",
        type: "error",
      };
      this.snackBarService.openSnackBar(snackBarData);
      return;
    }

    if (!this.date_to) {
      let snackBarData: SnackBarData = {
        message: "Please choose a end date.",
        title: "Failed!!!",
        type: "error",
      };
      this.snackBarService.openSnackBar(snackBarData);
      return;
    }
   
    if (this.date_to < this.date_from) {
      let snackBarData: SnackBarData = {
        message: "End date should not be less than start date.",
        title: "Failed!!!",
        type: "error",
      };
      this.snackBarService.openSnackBar(snackBarData);
      return;
    }
    
    const formattedDate: string | null = this.datePipe.transform(
      this.date_from,
      "yyyy-MM-dd HH:mm:ss"
    );

    const formattedEndDate: string | null = this.datePipe.transform(
      this.date_to,
      "yyyy-MM-dd HH:mm:ss"
    );

    this.luckyDrawInfoModel.code = this.editForm.get("code")?.value;
    this.luckyDrawInfoModel.name = this.editForm.get("name")?.value;
    this.luckyDrawInfoModel.description = this.editForm.get("description")?.value;
    // this.luckyDrawInfoModel.extra_info.channel = this.editForm.get("target_channel")?.value;
    // this.luckyDrawInfoModel.extra_info.customer = this.editForm.get("target_audience")?.value;
    // this.luckyDrawInfoModel.extra_info.min_order_amount = this.editForm.get("qualified_spend")?.value;
    this.luckyDrawInfoModel.date_from = formattedDate;
    this.luckyDrawInfoModel.date_to = formattedEndDate;
    this.luckyDrawInfoModel.crm_lucky_draw_hdr_id = this.editForm.get(
      "crm_lucky_draw_hdr_id"
    )?.value;
    this.luckyDrawInfoModel.prize_settings = this.selectedItems;
    const num_winner = this.selectedItems.reduce((sum, reward) => sum + reward.count, 0);
    this.luckyDrawInfoModel.num_winner = num_winner;
    console.log(this.luckyDrawInfoModel)
    // this.luckyDrawInfoModel.prize_settings = this.selectedItems.map(
    //   (reward: any) => {
    //     return {
    //       id: reward.id,
    //       qty: this.editForm.get("count")?.value,
    //     };
    //   }
    // );
    // this.luckyDrawInfoModel.prize_settings=this.selectedItems;
    // if (this.luckyDrawInfoModel.is_valid == 1) {
    //   if (this.luckyDrawInfoModel.start_date != this.luckyDrawInfoModel.end_date) {

    //   }
    // }
    this.luckDrawService.update(this.luckyDrawInfoModel).subscribe({
      next: (response) => {
        if (response.status == "SUCCESS") {
          this.isFormDirty = false;
          // this.buildRewardFromResponse(response);
          let snackBarData: SnackBarData = {
            type: "success",
            title: "Saved",
            message:
              "The record for " +
              this.luckyDrawInfoModel.name +
              "  " + response.message,
          };
          this.snackBarService.openSnackBar(snackBarData);
          this.router.navigate(['draw']);

        }
        else {

          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };

          this.snackBarService.openSnackBar(snackBarData);

        }
      },
      error: (_error) => {
        let snackBarData: SnackBarData = {
          message: "Failed to update. Connection to the server failed",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }

  saleChannels = [
    { title: 'Online', value: 1 },
    { title: 'Instore', value: 2 },
    { title: 'Both', value: 3 },
  ];

  targetAudience = [
    { title: 'Member', value: 1 },
    { title: 'Guest', value: 2 },
    { title: 'Both', value: 3 },
  ];

  // Function to handle Sales Channels checkboxes
  onSalesChannelChange($event: any): void {
    this.isFormDirty = true;
  }

  /**
   * Loads the LuckyDraw
   */
  loadProducts(): void {
    let requestData = {
      search: {
        filters: {
          column: [],
          scope: { limit: 0, offset: 0 },
        },
        sort: [{ column: "name", order: "asc" }],
      },
    };

    // this.luckDrawService.getProducts(requestData).subscribe({
    //   next: (response) => {
    //     if (response.status === "SUCCESS") {
    //       // Exclude products where item_type is 3
    //       this.productList = response.data.items.filter(
    //         (product: { item_type: number }) => product.item_type !== 3
    //       );
    //     } else {
    //       let snackBarData: SnackBarData = {
    //         message: response.message,
    //         title: "Failed!!!",
    //         type: "error",
    //       };
    //       this.snackBarService.openSnackBar(snackBarData);
    //     }
    //   },
    //   error: (error) => {
    //     let snackBarData: SnackBarData = {
    //       message: "Failed to load products.",
    //       title: "Failed!!!",
    //       type: "error",
    //     };
    //     this.snackBarService.openSnackBar(snackBarData);
    //   },
    // });
  }

  openSelectionTypeDialog(triggerBy: any): void {
    const dialogRef = this.dialog.open(LuckyDrawProductBrowseDialogComponent, {
      width: "400px",
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // this.openProductBrowseDialog(result.type, triggerBy);
      }
    });
  }

  isDisabled(): boolean {
    // Add your conditions here to determine if inputs should be disabled
    const canOverride = this.selectedSystemEvent && this.selectedSystemEvent.can_override === 0;
    const isSelectedSystemEvent = this.selectedSystemEvent !== null;

    return canOverride && isSelectedSystemEvent;
  }

  // loadMailTemplateList(): void {
  //   let requestData = {
  //     search: {
  //       filters: {
  //         column: [],
  //         scope: { limit: 0, offset: 0 },
  //       },
  //       sort: [{ column: "name", order: "asc" }],
  //     },
  //   };

  //   this.luckDrawService.getMailTemplateList(requestData).subscribe({
  //     next: (response) => {
  //       if (response.status === "SUCCESS") {
  //         this.mailTemplateList = response.data.template_list;
  //       } else {
  //         let snackBarData: SnackBarData = {
  //           message: response.message,
  //           title: "Failed!!!",
  //           type: "error",
  //         };
  //         this.snackBarService.openSnackBar(snackBarData);
  //       }
  //     },
  //     error: (error) => {
  //       let snackBarData: SnackBarData = {
  //         message: "Failed to load mail template lists.",
  //         title: "Failed!!!",
  //         type: "error",
  //       };
  //       this.snackBarService.openSnackBar(snackBarData);
  //     },
  //   });
  // }


  /**
   * Creates the column filter conditions based on search criteria
   * @returns Filter condions for columns
   */
  getColumnFilter(): ColumnFilter[] {

    return this.searchColumFilter;
  }

  /**
  * Creates the column filter conditions based on search criteria
  * if customers are selected and filtering for selected customer add filtered customer ids
  * @returns Filter condions for columns
  */
  getSearchParam(): SearchParam {

    let searchParam = JSON.parse(JSON.stringify(this.searchColumFilter));
    if (this.selectedRewardFilter !== undefined) {
      if (searchParam.advSearch !== undefined)
        searchParam.advSearch.push(this.selectedRewardFilter);
      else
        searchParam.advSearch = [this.selectedRewardFilter];
    }
    return searchParam;

  }
  
   /**
   * Create sort order
   * @returns sort criteria
   */
   getSort(): SearchSort[] {
    return this.columnSortData;
  }
  
  loadMainDraws(){
    let date = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    let requestData: RequestData = {
      "search": {
        "filters": {
          "simpleSearch": [

          ],
          "advSearch": [
            {
              "column": "is_valid",
              "operator": "=",
              "value": 1
          
            }
          ],
          "scope": {
            "limit": 10,
            "offset": 0
          }
        },
        "sort": [
          {
            "column": "name",
            "order": "asc"
          }
        ]
      }
    };
    this.luckDrawService.getMainDraws(requestData).subscribe({
      next: (response) => {
        if (response.status === "SUCCESS") {
          this.mainDrawList = response.data.ldHdr;
         
        
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: "Failed to load mail template lists.",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }

  loadSystemEvents(): void {
    let requestData = {
      search: {
        filters: {
          column: [],
          scope: { limit: 0, offset: 0 },
        },
        sort: [{ column: "name", order: "asc" }],
      },
    };

    // this.luckDrawService.getEvents().subscribe({
    //   next: (response) => {
    //     if (response.status === "SUCCESS") {
    //       this.systemEvent = response.data.events;
    //     } else {
    //       let snackBarData: SnackBarData = {
    //         message: response.message,
    //         title: "Failed!!!",
    //         type: "error",
    //       };
    //       this.snackBarService.openSnackBar(snackBarData);
    //     }
    //   },
    //   error: (error: any) => {
    //     let snackBarData: SnackBarData = {
    //       message: "Failed to retrieve events from the server.",
    //       title: "Error",
    //       type: "error",
    //     };
    //     this.snackBarService.openSnackBar(snackBarData);
    //   },
    // });
  }

  /**
   * Open editor dialog
   * @param params 
   */
  openRewardBrowseDialog(): void {
    // if (params === 'terms') {
    //   this.displayTitle = 'Terms and Conditions';
    //   this.editorData = this.luckyDrawInfoModel.tc_info
    // } else if (params === 'privacy') {
    //   this.displayTitle = 'Privacy Policy';
    //   this.editorData = this.luckyDrawInfoModel.privacy_policy;
    // }
    // let browseItems = [];
    // let requestData: RequestData = {"search":{"filters":{"simpleSearch":[],"advSearch":[{"column":"apply_customer_filter","operator":"=","value":"2"},{"column":"reward_eligibility","operator":"=","value":"2"},{"column":"status","operator":"in","value":["1"],"type":"list"}],"scope":{"limit":9999.99,"offset":0}},"sort":[{"column":"name","order":"asc"}]}};
    // this.luckDrawService.getRewards(requestData).subscribe({
    //   next: (response) => {
    //     if (response.status === "SUCCESS") {
    //       this.rewardList = response.data.rewards;
          
      
    //     } else {
    //       let snackBarData: SnackBarData = {
    //         message: response.message,
    //         title: "Failed!!!",
    //         type: "error",
    //       };
    //       this.snackBarService.openSnackBar(snackBarData);
    //     }
    //   },
    //   error: (error) => {
    //     let snackBarData: SnackBarData = {
    //       message: "Failed to load reward list.",
    //       title: "Failed!!!",
    //       type: "error",
    //     };
    //     this.snackBarService.openSnackBar(snackBarData);
    //   },
    // });
    const editorConfig = {
      title: "Rewards",
      items: this.rewardList,
      selectedItems: this.selectedItems,
    };

    const dialogRef = this.dialog.open(RewardDialogComponent, {
      width: "30%",
      height: "80%", // Adjust the diamensions as needed
      data: editorConfig,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.selectedItems = result.selectedItems; // Store selected items in the component property

      }
    });
  }

  removeFromReward(id: number): void {
    if (id == 0) {
      this.selectedItems.length = 0;
    } else {
      if (this.selectedItems !== undefined) {
        const index = this.selectedItems.findIndex(
          (i: any) => i.crm_reward_id == id
        );
        if (index !== -1) {
          this.selectedItems.splice(index, 1);
        }
      }
    }
    console.log(this.selectedItems)
  }

}

