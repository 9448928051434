import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ToolbarComponent } from './common/components/toolbar/toolbar.component';
import { SidenavComponent } from './common/components/sidenav/sidenav.component';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MaterialModule } from './material.module';

import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DashboardItemComponent } from './pages/dashboard/components/dashboard-item/dashboard-item.component';
import { UserInfoComponent } from './common/components/user-info/user-info.component';
import { UserEditComponent } from './pages/user/user-edit/user-edit.component';
import { ConfirmDialogComponent } from './common/components/confirm-dialog/confirm-dialog.component';
import { DashboardEditComponent } from './pages/dashboard/dashboard-edit/dashboard-edit.component';
import { MasterFloatingMenuComponent } from './common/components/master-floating-menu/master-floating-menu.component';
import { UserListItemComponent } from './pages/user/components/user-list-item/user-list-item.component';
import { UserComponent } from './pages/user/user.component';
import { SnackBarComponent } from './common/components/snackbar/snackbar.component';
import { UserEditDialogComponent } from './pages/user/dialogs/user-edit-dialog/user-edit-dialog.component';
import { StylePaginatorDirective } from './common/components/paginator/style-paginator.directive';
import { SimplePaginatorComponent } from './common/components/paginator/simple-paginator/simple-paginator.component';
import { TokenInterceptorService } from './auth/token-interceptor.service';
import { SorterComponent } from './common/components/sorter/sorter.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { OrderComponent } from './pages/order/order.component';
import { CouponComponent } from './pages/coupon/coupon.component';
import { LoaderComponent } from './common/components/loader/loader.component';
import { RewardCategoryComponent } from './pages/reward-category/reward-category.component';
import { RewardCategoryEditDialogComponent } from './pages/reward-category/dialogs/reward-category-edit-dialog/reward-category-edit-dialog.component';
import { RewardComponent } from './pages/reward/reward.component';
import { RewardEditComponent } from './pages/reward/reward-edit/reward-edit.component';
import { TagComponent } from './pages/tag/tag.component';
import { DiscountComponent } from './pages/discount/discount.component';
import { CustomerSummaryComponent } from './pages/customer/components/customer-summary/customer-summary.component';
import { CustomerTagEditDialogComponent } from './pages/customer/dialogs/customer-tag-edit-dialog/customer-tag-edit-dialog.component';
import { CustomerEditComponent } from './pages/customer/customer-edit/customer-edit.component';
import { SearchFilterComponent } from './common/components/search-filter/search-filter.component';
import { FilterItemComponent } from './common/components/search-filter/filter-item/filter-item.component';
import { TagSummaryComponent } from './pages/tag/components/tag-summary/tag-summary.component';
import { RewardCategorySummaryComponent } from './pages/reward-category/components/reward-category-summary/reward-category-summary.component';
import { UserSummaryComponent } from './pages/user/components/user-summary/user-summary.component';
import { RewardSummaryComponent } from './pages/reward/components/reward-summary/reward-summary.component';
import { RewardSubmenuComponent } from './common/components/sidenav/submenu/reward-submenu/reward-submenu.component';
import { CustomerOptionbarComponent } from './pages/customer/components/customer-optionbar/customer-optionbar.component';
import { CustomerSelectActionListComponent } from './pages/customer/components/customer-select-action-list/customer-select-action-list.component';
import { SegmentComponent } from './pages/segment/segment.component';
import { ExtraPagesMenuComponent } from './common/components/extra-pages-menu/extra-pages-menu.component';
import { UserGroupComponent } from './pages/user-group/user-group.component';
import { TagEditComponent } from './pages/tag/tag-edit/tag-edit.component';
import { SegmentSummaryComponent } from './pages/segment/components/segment-summary/segment-summary.component';
import { SearchFilterSaveDialogComponent } from './common/components/search-filter/dialogs/search-filter-save-dialog/search-filter-save-dialog.component';
import { SearchFilterLoadDialogComponent } from './common/components/search-filter/dialogs/search-filter-load-dialog/search-filter-load-dialog.component';
import { SegmentEditComponent } from './pages/segment/segment-edit/segment-edit.component';
import { RewardCategoryEditComponent } from './pages/reward-category/reward-category-edit/reward-category-edit.component';
import { CustomerSegmentEditDialogComponent } from './pages/customer/dialogs/customer-segment-edit-dialog/customer-segment-edit-dialog.component';
import { UserGroupEditComponent } from './pages/user-group/user-group-edit/user-group-edit.component';
import { UserGroupSummaryComponent } from './pages/user-group/user-group-summary/user-group-summary.component';
import { RfmSettingsComponent } from './pages/rfm-settings/rfm-settings.component';
import { RewardProductBrowseDialogComponent } from './pages/reward/dialogs/reward-product-browse-dialog/reward-product-browse-dialog.component';
import { RewardProductItemsDialogComponent } from './pages/reward/dialogs/reward-product-items-dialog/reward-product-items-dialog.component';
import { RewardSegmentEditDialogComponent } from './pages/reward/dialogs/reward-segment-edit-dialog/reward-segment-edit-dialog.component';
import { AnalysisComponent } from './pages/analysis/analysis.component';
import { ReportComponent } from './pages/report/report.component';
import { ImageUploadComponent } from './pages/reward/dialogs/image-upload/image-upload.component';
import { FilterDialogComponent } from './common/components/search-filter/dialogs/filter-dialog/filter-dialog.component';
import { RewardCustFilterDialogComponent } from './pages/reward/dialogs/reward-cust-filter-dialog/reward-cust-filter-dialog.component';
import { PaymentTrendDialogComponent } from './pages/customer/dialogs/payment-trend-dialog/payment-trend-dialog.component';
import { OrderSummaryComponent } from './pages/order/components/order-summary/order-summary.component';
import { ChangePasswordDialogComponent } from './pages/user/dialogs/change-password-dialog/change-password-dialog.component';
import { SystemFunctionComponent } from './pages/system-function/system-function.component';
import { PurchaseTimeDialogComponent } from './pages/customer/dialogs/purchase-time-dialog/purchase-time-dialog.component';
import { RewardProductsDialogComponent } from './pages/reward/dialogs/reward-products-dialog/reward-products-dialog.component';
import { MastersOptionbarComponent } from './common/components/masters-optionbar/masters-optionbar.component';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateModule } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { LuckyDrawSettingComponent } from './pages/lucky-draw-settings/lucky-draw-settings.component';
import { LuckydrawSummaryComponent } from './pages/lucky-draw-settings/components/luckydraw-summary/luckydraw-summary.component';
import { LuckyDrawSettingEditComponent } from './pages/lucky-draw-settings/lucky-draw-settings-edit/lucky-draw-settings-edit.component';
import { LuckyDrawEditDialogComponent } from './pages/lucky-draw-settings/dialogs/luckydraw-edit-dialog/luckdraw-edit-dialog.component';
import { LuckyDrawImageUploadComponent } from './pages/lucky-draw-settings/dialogs/image-upload/image-upload.component';
import { LuckyDrawCustFilterDialogComponent } from './pages/lucky-draw-settings/dialogs/luckdraw-cust-filter-dialog/luckydraw-cust-filter-dialog.component';
import { LuckyDrawProductBrowseDialogComponent } from './pages/lucky-draw-settings/dialogs/luckydraw-product-browse-dialog/luckydraw-product-browse-dialog.component';
import { LuckyDrawProductItemsDialogComponent } from './pages/lucky-draw-settings/dialogs/luckydraw-product-items-dialog/luckydraw-product-items-dialog.component';
import { LuckyDrawProductsDialogComponent } from './pages/lucky-draw-settings/dialogs/luckydraw-products-dialog/luckydraw-products-dialog.component';
import { LuckyDrawSegmentEditDialogComponent } from './pages/lucky-draw-settings/dialogs/luckydraw-segment-edit-dialog/luckydraw-segment-edit-dialog.component';
import { LuckyDrawEntryComponent } from './pages/lucky-draw-entry/lucky-draw-entry.component';
import { QuillEditorComponent, QuillModule } from 'ngx-quill';
import { HtmlEditComponent } from './pages/lucky-draw-settings/components/html-edit/html-edit.component';
import { LuckyDrawMenuComponent } from './pages/lucky-draw-settings/lucky-draw-menus/lucky-draw-menu.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { LuckyDrawComponent } from './pages/lucky-draw/lucky-draw.component';
import { LuckyDrawEditComponent } from './pages/lucky-draw/luckydraw-edit/luckydraw-edit.component';
import { RewardDialogComponent } from './pages/lucky-draw/components/reward-dialog/reward-dialog.component';
import { LuckyDrawWinnerComponent } from './pages/lucky-draw-winner/lucky-draw-winner.component';
import { LuckyDrawWinnerActionSelectionListComponent } from './pages/lucky-draw-winner/lucky-draw-winner-action-selection-list/lucky-draw-winner-action-selection-list.component';
import { WinnerStatusConfirmationDialogComponent } from './pages/lucky-draw-winner/winner-status-confirmation-dialog/winner-status-confirmation-dialog.component';
import { LuckyDrawWinnerPublishDialogComponent } from './pages/lucky-draw-winner/lucky-draw-winner-publish-dialog/lucky-draw-winner-publish-dialog.component';
import { RewardMenuComponent } from './pages/reward/reward-menu/reward-menu.component';
// Custom Date Format
export const MY_DATE_FORMATS = {
    parse: {
      dateInput: 'DD-MM-YYYY',
    },
    display: {
      dateInput: 'DD-MM-YYYY',
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'DD-MM-YYYY',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };
@NgModule({ declarations: [
        AppComponent,
        ToolbarComponent,
        SidenavComponent,
        LoginComponent,
        DashboardComponent,
        DashboardItemComponent,
        UserInfoComponent,
        ConfirmDialogComponent,
        DashboardEditComponent,
        MasterFloatingMenuComponent,
        MastersOptionbarComponent,
        UserComponent,
        UserListItemComponent,
        SnackBarComponent,
        UserEditDialogComponent,
        StylePaginatorDirective,
        SimplePaginatorComponent,
        SorterComponent,
        SearchFilterComponent,
        FilterItemComponent,
        CustomerComponent,
        OrderComponent,
        CouponComponent,
        LoaderComponent,
        UserEditComponent,
        RewardCategoryComponent,
        RewardEditComponent,
        RewardCategoryEditDialogComponent,
        RewardComponent,
        TagComponent,
        DiscountComponent,
        SystemFunctionComponent,
        CustomerSummaryComponent,
        CustomerTagEditDialogComponent,
        CustomerEditComponent,
        SearchFilterComponent,
        TagSummaryComponent,
        RewardCategorySummaryComponent,
        UserSummaryComponent,
        RewardProductBrowseDialogComponent,
        RewardCategoryEditDialogComponent,
        RewardProductItemsDialogComponent,
        RewardSegmentEditDialogComponent,
        RewardSummaryComponent,
        RewardSubmenuComponent,
        CustomerOptionbarComponent,
        CustomerSelectActionListComponent,
        ExtraPagesMenuComponent,
        UserGroupComponent,
        ChangePasswordDialogComponent,
        TagEditComponent,
        SegmentSummaryComponent,
        SegmentComponent,
        SegmentEditComponent,
        SearchFilterLoadDialogComponent,
        SearchFilterSaveDialogComponent,
        RewardCategoryEditComponent,
        CustomerSegmentEditDialogComponent,
        ImageUploadComponent,
        PaymentTrendDialogComponent,
        SearchFilterSaveDialogComponent,
        UserGroupEditComponent,
        UserGroupSummaryComponent,
        RfmSettingsComponent,
        AnalysisComponent,
        ReportComponent,
        OrderSummaryComponent,
        FilterDialogComponent,
        RewardCustFilterDialogComponent,
        PurchaseTimeDialogComponent,
        RewardProductsDialogComponent,
        LuckyDrawSettingComponent,
        LuckydrawSummaryComponent,
        LuckyDrawSettingEditComponent,
        LuckyDrawEditDialogComponent,
        LuckyDrawImageUploadComponent,
        LuckyDrawCustFilterDialogComponent,
        LuckyDrawProductBrowseDialogComponent,
        LuckyDrawProductItemsDialogComponent,
        LuckyDrawProductsDialogComponent,
        LuckyDrawSegmentEditDialogComponent,
        LuckyDrawEntryComponent,
        HtmlEditComponent,
        LuckyDrawMenuComponent,
        AccessDeniedComponent,
        LuckyDrawWinnerComponent,
        LuckyDrawComponent,
        LuckyDrawEditComponent,
        RewardDialogComponent,
        LuckyDrawWinnerActionSelectionListComponent,
        WinnerStatusConfirmationDialogComponent,
        LuckyDrawWinnerPublishDialogComponent,
        RewardMenuComponent
    ],
    
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgxChartsModule,
        ReactiveFormsModule,
        MaterialModule,
        MatMomentDateModule, // Moment adapter for MatDatepicker

        FormsModule,
         QuillModule.forRoot({
          modules: {
            resize: {
              
              showSize: false,
              toolbar: {
                sizeTools: false,
                alingTools: true
              },
            },
            
          },
          theme: 'snow'
        })
      ], 
      providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true
        },

        {
            provide: MAT_DATE_FORMATS,
            useValue: MY_DATE_FORMATS
          },
          {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, 
            useValue: { useUtc: true } // optional, if you want UTC handling
          },
        
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
