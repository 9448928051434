<div class="summary-page-root-container order-summary-root-container" [style.width]=" show ? '320px' : '0px' ">
    <div [ngClass]="{ 'close-btn' : show , 'open-btn' :!show  }">
        <button mat-icon-button (click)="close()">
            @if(show){
            <svg x="0px" y="0px" width="20px" height="20px" viewBox="0 0 24 24" enable-background="new 0 0 24 24"
                xml:space="preserve" fill="#5F6368">
                <path d="M8.59,16.59L13.17,12L8.59,7.41L10,6l6,6l-6,6L8.59,16.59z"></path>
                <path fill="none" d="M0,0h24v24H0V0z"></path>
            </svg>
            }@else{
            <svg x="0px" y="0px" width="20px" height="20px" viewBox="0 0 24 24" enable-background="new 0 0 24 24"
                style="transform: rotate(180deg);" xml:space="preserve" fill="#5F6368">
                <path d="M8.59,16.59L13.17,12L8.59,7.41L10,6l6,6l-6,6L8.59,16.59z"></path>
                <path fill="none" d="M0,0h24v24H0V0z"></path>
            </svg>
            }
        </button>
    </div>
    @if(show && order!==undefined){
    <div class="page-container" style="overflow-y: auto">
        <div class="page-header">
            <div class="title">{{order.order_id}} </div>

        </div>
        <div class="page-content">
            <div class="section order-container">
                <div class="section-title">ORDER</div>
                <div class="section-content order-container-content">
                    <div class="order-count order-content-item">
                        <span class="order-count-title order-title">Date</span>:
                        <span class="order-count-value order-value">{{order.order_time| date: dateFormat }}</span>
                    </div>
                    <div class="order-count order-content-item">
                        <span class="order-count-title order-title">Shop</span>:
                        <span class="order-count-value order-value ">{{order.shop_name}}</span>
                    </div>

                    <div class="order-latest order-content-item">
                        <span class="order-latest-title order-title">Amount</span>:
                        <span class="order-latest-value order-value order-amount">{{
                            order.total_amount | currency : "USD" : "symbol" : "1.2-2"
                            }}</span>
                    </div>
                    <div class="order-latest order-content-item">
                        <span class="order-latest-title order-title">Tax Inv. No.</span>:
                        <span class="order-latest-value order-value order-amount">{{order.tax_invoice_no}}
                        </span>
                    </div>

                </div>

                <div class="section-footer"></div>
            </div>
            <div class="section order-container">

                <div class="section-title" style="margin-left:2px;">ITEMS</div>
                <div class="section-content order-container-content">
                    <table class="for-scroll">
                        <tr>
                            <th style="text-align:left">Item Name</th>
                            <th style="text-align:right">Qty</th>
                            <th style="text-align:right">Total</th>
                        </tr>

                        <ng-container *ngFor="let item of order.order_dtl">
                            <!-- Main Item -->
                            <tr>
                                <td>{{ item.sale_item_name }}</td>
                                <td style="text-align:right">{{ item.qty }}</td>
                                <td style="text-align:right">{{ item.item_total | currency:'USD':'symbol':'1.2-2' }}
                                </td>
                            </tr>
                      
                            @if(item.item_type === 2){
                                <tr *ngFor="let cust of item.customizations">
                                    <td style="padding-left: 20px">
                                        <div style="display: flex; align-items: center;">
                                            <mat-icon style="font-size:15px">filter_tilt_shift</mat-icon>
                                            <span style="margin-left: 10px;"> {{ cust.sale_item_name }} </span>
                                        </div>
                                    </td>
                                    <td style="text-align: right">{{ cust.qty }}</td>
                                    <td style="text-align: right">{{ cust.item_total | currency }}</td>
                                </tr>
                            }
                                
                            
                              
                            <!-- Combo Items -->

                            @if(item.item_type === 3){
                            @for(combo of getComboOptions(item); track combo.name){
                            <tr>
                                <td style="padding-left: 20px">
                                    <div style="display: flex; align-items: center">
                                        <mat-icon style="font-size:15px">brightness_1</mat-icon>
                                        <span style="margin-left: 10px">{{ combo.sale_item_name }}</span>
                                    </div>
                                </td>
                                <td style="text-align:right">{{ combo.qty }}</td>
                                <td style="text-align:right">{{ combo.item_total | currency:'USD':'symbol':'1.2-2' }}
                                </td>

                                <!-- Customizations for Combo Item -->

                            <tr *ngFor="let cust of getCustomizations(combo); trackBy: trackByName">

                                <td style="padding-left: 35px">
                                    <div style="display: flex; align-items: center">
                                        <mat-icon style="font-size:15px">filter_tilt_shift</mat-icon>
                                        <span style="margin-left: 10px">{{ cust.sale_item_name }}</span>
                                    </div>
                                </td>
                                <td style="text-align:right">{{ cust.qty }}</td>
                                <td style="text-align:right">{{ cust.item_total | currency:'USD':'symbol':'1.2-2' }}
                                </td>
                            </tr>

                            }

                            }
                            <!-- Discount if any -->
                            <tr *ngIf="item.total_discount_amount > 0">
                                <td colspan="3">
                                    <span>** Discount Applied: {{ item.total_discount_amount |
                                        currency:'USD':'symbol':'1.2-2' }}</span>
                                </td>
                            </tr>
                        </ng-container>
                    </table>


                </div>



            </div>
        </div>
        <div class="page-footer">
            <!-- <button mat-button color="primary" class="btn-edit"
                (click)="onEditAction()"><mat-icon>edit</mat-icon>Edit</button>
            <button mat-button color="accent" class="btn-delete" (click)="onDeleteAction()">
                <mat-icon>delete</mat-icon>Delete</button> -->
        </div>
    </div>
    }@else if(show && order==undefined){

    <div class="no-page-data">
        <img src="assets/images/side-bar-no-data.png">
        <span class="no-data-info">Nothing to show. Please select an order.</span>
    </div>
    }@else{}
</div>