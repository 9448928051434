<app-lucky-draw-menu> </app-lucky-draw-menu>

<div class="reward-component-container  page-root-container">
  <div class="page-header">
    <!-- <div class="page-title">Promotion</div> -->
    <div class="page-title">
      <img src="assets/images/menu/svg/ld-setup.svg" style="height: 24px;">

      <span>Draw Settings</span>
      <!-- <span>Draw</span> -->
    </div>
    <div class="page-actions">
      @if(permission.can_add){
        <button mat-button color="primary" class="btn-add" (click)="addClicked()">
        <div style="display: flex; gap: 5px; align-items: center;">
          <img style="height: 16px;" src="assets/images/icons/plus.svg"> <span>New</span>
        </div>
      </button>
    }      
    @if(permission.can_export){

      <button mat-button color="accent" class="btn-export" (click)="exportClicked()"
      [disabled]="IsDownloading">
      <img class="download-icon"
              [src]="IsDownloading ? 'assets/images/animated/download/download-anim.svg' : 'assets/images/animated/download/download-no-anim.svg'">
      Export
</button>
    }
    </div>
  </div>

  <div class="data-container">
    <app-loader [show]="isLoading"></app-loader>

    <!-- <app-search-filter #searchFilter [length]="pagingData.length" [pageSize]="pagingData.pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageIndex]="pagingData.pageIndex" [filter]="filter"
        (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true">
      </app-search-filter> -->
    <app-search-filter #searchFilter [pagingData]="pagingData" [pageSizeOptions]="[5,10,20,50,100]" [filter]="filter"
      (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true">
    </app-search-filter>

    <section tabindex="0" class="table-container">
      <table matSort mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef (click)="sortData($event)" class="item-header">#</th>
          <td mat-cell *matCellDef="let element; let i = index" class="item-cloum">{{
            (pagingData.pageIndex*pagingData.pageSize) + i + 1 }}</td>
        </ng-container>
        <ng-container matColumnDef="code">
          <th mat-sort-header="code" mat-header-cell *matHeaderCellDef (click)="sortData($event)" style="width: 100px;">
            Code
          </th>
          <td mat-cell *matCellDef="let item">{{ item.code }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-sort-header="name" mat-header-cell *matHeaderCellDef (click)="sortData($event)">
            Name
          </th>
          <td mat-cell *matCellDef="let item">{{ item.name }}</td>
        </ng-container>

        <!-- <ng-container matColumnDef="crm_reward_category_id">
            <th mat-sort-header="crm_reward_category_id" mat-header-cell *matHeaderCellDef (click)="sortData($event)"
              class="category-name-header">
              Category
            </th>
            <td mat-cell *matCellDef="let item" class="category-name-column">{{ item.category_name }}</td>
          </ng-container> -->
        <ng-container matColumnDef="status">
          <th mat-sort-header="status" mat-header-cell *matHeaderCellDef (click)="sortData($event)"
            class="status-header">
            Status
          </th>
          <td mat-cell *matCellDef="let item" class="status-column">
            <div class="reward-active-status">
              @if(item.is_published === 1){
              <div class="status active">
                Active <span></span>
              </div>
              }@else if(item.is_published === 2){
              <div class="status expired">
                Expired
              </div>
              }
              @else{
              <div class="status unpublished">
                Unpublished
              </div>
              }
            </div>
          </td>
        </ng-container>
       
        <ng-container matColumnDef="start_date">
          <th mat-sort-header="start_date" mat-header-cell *matHeaderCellDef (click)="sortData($event)"
            class="start-date-header">
            Start Date
          </th>
          <td mat-cell *matCellDef="let item" class="start-date-column">
            <!-- {{ getUpdatedDate(item) }} -->
            {{ item.start_date | date: dateFormat }}

          </td>
        </ng-container>
        <ng-container matColumnDef="end_date">
          <th mat-sort-header="end_date" mat-header-cell *matHeaderCellDef (click)="sortData($event)"
            class="end-date-header">
            End Date
          </th>
          <td mat-cell *matCellDef="let item" class="end-date-column">
            @if(item.valid_for!= 0){
            {{ item.end_date | date: dateFormat }}


            }
            @else{

            }
          </td>
        </ng-container>

        <ng-container matColumnDef="is_valid">
          <th mat-sort-header="is_valid" mat-header-cell *matHeaderCellDef (click)="sortData($event)" style="width: 30px;">
            Valid
          </th>
          <td mat-cell *matCellDef="let item">
            @if (item.is_valid==1) {
            <span style='font-size:20px;'>&#10004;</span>
            }@else {

            }
          </td>
        </ng-container>

        <ng-container matColumnDef="action" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell class="row-menu-btn" style="width: 50px" *matCellDef="let rowItem">
          
            <a mat-icon-button class="row-menu-btn" [matMenuTriggerFor]="rowMenu"
              (click)="onRowSelected(rowItem); $event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </a>
          
          
            <mat-menu #rowMenu>
              @if(permission.can_edit || permission.can_add){
              <button mat-menu-item (click)="onDelete(rowItem)">
                Delete
              </button>
            }
              @if(permission.can_edit){
              <button mat-menu-item (click)="onEdit(rowItem)">Edit</button>
              }@else{
                <button mat-menu-item (click)="onEdit(rowItem)">View</button>

              }
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row class="table-element-row" *matRowDef="let item; columns: displayedColumns"
          (click)="onRowSelected(item)" (dblclick)="onEdit(item)"
          [ngClass]="{ 'row-selected': item.id === selectedItem?.id }"></tr>
      </table>
    </section>
    @if(pagingData.length<=0){ <div class="no-data-container">
      <img src="assets/images/no-data.png" />
      <span class="no-data-info">No data found. Please remove or redefine the filters if any.</span>
  </div>
  }
</div>
</div>
<!-- <app-Luckydraw-summary [reward]="selectedItem" (onEdit)="onEdit($event)"
  (onDelete)="onDelete($event)"></app-Luckydraw-summary> -->