export const environment = {
    production: false,
    baseUrl: 'https://dev.crm-api.mojosoft.work',
    userId: 'crm',
    password: '1234',
    appKey:"e568458e9121478125d076b344e26c9e",
    assetsUrl:"https://dev.assets.crm.mojosoft.work",
    version:"DEV 0.0.1",
    buildNo:202504021222,
  };
  
