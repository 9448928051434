import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { SystemSettingsService } from 'src/app/common/services/system-settings.service';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
  standalone: false
})
export class OrderSummaryComponent {
  @Input() order: any;

  // @Output() onEdit: EventEmitter<any> = new EventEmitter();
  // @Output() onDelete: EventEmitter<any> = new EventEmitter();

  show: boolean = false;
  dateFormat: string;
  option: any;

  constructor(private systemSettingService: SystemSettingsService) {
    this.dateFormat = systemSettingService.getDefaultDateFormat();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['order']) {
      this.show = (this.order !== undefined);

    }
  }
  trackByName(index: number, item: any): string {
    return item.sale_item_name;
  }
  
  getComboOptions(item: any): any[] {
    return item.combo_items || [];
  }
  
  getCustomizations(item: any): any[] {
    return item.customizations || [];
  }
  





  //   isItemInCustomizationsOrCombos(item: any): boolean {
  //     let allCustomizations: any[] = [];
  //     let allCombos: any[] = [];

  //     // Flatten all customizations and combo items into separate arrays
  //     this.order.order_dtl.forEach((orderItem: { customizations: string; combo_items: string; }) => {
  //         if (orderItem.customizations) {
  //             allCustomizations = allCustomizations.concat(JSON.parse(orderItem.customizations));
  //         }
  //         if (orderItem.combo_items) {
  //             allCombos = allCombos.concat(JSON.parse(orderItem.combo_items));
  //         }
  //     });

  //     // Check if the current item is in the customizations or combo lists
  //     return allCustomizations.some(cust => cust.sale_item_name === item.sale_item_name) ||
  //            allCombos.some(combo => combo.sale_item_name === item.sale_item_name);
  // }

  /**
   * Closes the side panel
   */
  close(): void {
    this.show = !this.show;
  }
  /**
   * Edit the current selected item
   */
  // onEditAction() {
  //   this.onEdit.emit(this.order);
  // }

  /**
   * Delete the currnet selected item.
   */
  // onDeleteAction() {
  //   this.onDelete.emit(this.order);
  // }
}

