<app-customer-optionbar [tags]="tags" [segments]="segments" [filters]="favoriteFilters"
        (tagSelected)="onTagSelected($event)" (segmentSelected)="onSegmentSelected($event)"
        (filterSelected)="onFilterSelected($event)" (reset)="optionBarFilterReset($event)">
</app-customer-optionbar>

<div class="customoer-component-container page-root-container">
        <app-loader [show]="isLoading"></app-loader>

        <div class="page-header">
                <div class="page-title">
                        <img src="assets/images/menu/svg/customer.svg" style="width:24px; height:24px;">
                        <span>Customers</span>
                </div>

                <!-- <div class="page-title">Customers</div> -->
                <div class="filler"></div>
                <!-- <app-customer-select-action-list [tags]="tags" [segments]="segments"
                        (tagsApplied)="onTagsApplied($event)" (segmentApplied)="onSegmentApplied($event)"
                        *ngIf="getCurPageSelectedCusts().length>0"></app-customer-select-action-list> -->

                <div class="page-actions">
                        <!-- <button mat-button color="primary" class="btn-add"
                                (click)="addClicked()"><mat-icon>person_add</mat-icon>New</button> -->
                        @if(permission.can_export){

                        <button mat-button color="accent" class="btn-export" (click)="exportClicked()"
                                [disabled]="IsDownloading">
                                <img class="download-icon"
                                        [src]="IsDownloading ? 'assets/images/animated/download/download-anim.svg' : 'assets/images/animated/download/download-no-anim.svg'">
                                Export
                        </button>
                        }

                </div>
        </div>

        <div class="data-container ">
                <!-- <app-search-filter #searchFilter [length]="pagingData.length" [pageSize]="pagingData.pageSize"
                        [pageSizeOptions]="[5,10,20,50,100]" [pageIndex]="pagingData.pageIndex" [filter]="filter"
                        (page)="onePageEvent($event)" [module]='"customer"' (onApply)="onFilterApplied($event)"
                        [hidePageSize]="true">
                </app-search-filter> -->
                <app-search-filter #searchFilter [pagingData]="pagingData" [pageSizeOptions]="[5,10,20,50,100]"
                        [filter]="filter" (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)"
                        [hidePageSize]="true" [module]='"customer"' [enableCustFilter]="true">
                </app-search-filter>
                <!-- <div *ngIf="selection.selected.length>0" class="notification-bar"> -->
                <div [style.height]="selection.selected.length>0 ? '60px' : '0px' " class="notification-bar">

                        <span class="notification">{{getSelectionNotificationMsg()}}</span>
                        <!-- <button mat-flat-button *ngIf="selection.selected.length!==pagingData.length">Select All</button> -->
                </div>
                <!-- @if(pagingData.length>0){
                @if(!isHandset){ -->
                <!-- <ng-container *ngIf="!isHandset"> -->
                        <section tabindex="0" class="table-container">
                                <table matSort mat-table [dataSource]="dataSource">
                                    <!-- Row menu -->
                                    <ng-container matColumnDef="selectMenu">
                                        <th mat-header-cell *matHeaderCellDef style="width: 26px; padding: 0px;" [attr.rowspan]="2">
                                            <a mat-icon-button class="row-selection-menu" [matMenuTriggerFor]="selectionMenu">
                                                <mat-icon>more_vert</mat-icon>
                                            </a>
                                            <mat-menu #selectionMenu>
                                                <button mat-menu-item (click)="toglleDisplaySelected()">
                                                    {{ showSelectedCustomers ? "Show all" : "Show Selected Only" }}
                                                </button>
                                                <button mat-menu-item (click)="clearAllSelection()">Clear All Selection</button>
                                            </mat-menu>
                                        </th>
                                        <td mat-cell *matCellDef="let row" style="width: 26px;"></td>
                                    </ng-container>
                            
                                    <!-- Checkbox Column -->
                                    <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef style="width: 30px; padding: 0px;" [attr.rowspan]="2">
                                            <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                                [checked]="selection.hasValue() && isAllSelected()"
                                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                                            </mat-checkbox>
                                        </th>
                                        <td mat-cell *matCellDef="let row" style="width: 30px; padding: 0px;">
                                            <mat-checkbox (click)="$event.stopPropagation()"
                                                (change)="$event ? selection.toggle(row.id) : null"
                                                [checked]="selection.isSelected(row.id)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>
                            
                                    <!-- Serial Number Column -->
                                    <ng-container matColumnDef="item">
                                        <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">#</th>
                                        <td mat-cell *matCellDef="let customer; let i = index">
                                            {{ (pagingData.pageIndex * pagingData.pageSize) + i + 1 }}
                                        </td>
                                    </ng-container>
                            
                                    <!-- Customer Column -->
                                    <ng-container matColumnDef="customer">
                                        <th mat-sort-header="first_name" mat-header-cell *matHeaderCellDef [attr.rowspan]="2" style="min-width:100px;" (click)="sortData($event)">
                                            Customer
                                        </th>
                                        <td mat-cell *matCellDef="let customer" style="min-width:100px;">
                                            <div class="col-customer">
                                                <span class="cust-name">{{ customer.first_name }} {{ customer.last_name }}</span>
                                                <span class="cust-email">{{ customer.email }}
                                                    <a href="javascript:void(0)" (click)="copyToClipboard(customer.email)" title="Copy Email">
                                                        <mat-icon style="font-size: 16px; vertical-align: middle; color:#7f112e">content_copy</mat-icon>
                                                    </a>
                                                </span>
                                            </div>
                                        </td>
                                    </ng-container>
                            
                                    <!-- Customer Type Column -->
                                    <ng-container matColumnDef="customer_type">
                                        <th mat-sort-header="customer_type" mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="customer-type-header" (click)="sortData($event)">
                                            Customer Type
                                        </th>
                                        <td mat-cell *matCellDef="let customer" class="customer-type-column">
                                            <div *ngIf="customer.customer_type === 1" class="status register">Registered</div>
                                            <div *ngIf="customer.customer_type === 2" class="status guest">Guest</div>
                                            <div *ngIf="customer.customer_type === 0" class="status social">Social</div>
                                        </td>
                                    </ng-container>
                            
                                    <!-- Member Since Column -->
                                    <ng-container matColumnDef="mem_since">
                                        <th mat-sort-header="id" mat-header-cell *matHeaderCellDef style="width:80px;text-align: right;" [attr.rowspan]="2" (click)="sortData($event)">
                                            Mbr. Since<br>(Months)
                                        </th>
                                        <td mat-cell *matCellDef="let customer" class="mem-since" style="width:80px;padding-right:28px;text-align: right;">
                                            {{ customer.mem_since }}
                                        </td>
                                    </ng-container>
                            
                                    <!-- Score Group Header -->
                                    <ng-container matColumnDef="scoreGroup">
                                        <th mat-header-cell *matHeaderCellDef colspan="3" style="text-align: center;width:180px;padding-right:28px;">Score</th>
                                    </ng-container>
                            
                                    <!-- Recency Score Column -->
                                    <ng-container matColumnDef="recency_score">
                                        <th mat-sort-header="recency_score" mat-header-cell *matHeaderCellDef style="width:80px;text-align: center;" (click)="sortData($event)">
                                            Recency
                                        </th>
                                        <td mat-cell *matCellDef="let customer" style="width:80px; padding-right:28px; text-align: right;">
                                            {{ customer.recency_score ?? 0 }}
                                        </td>
                                    </ng-container>
                            
                                    <!-- Frequency Score Column -->
                                    <ng-container matColumnDef="frequency_score">
                                        <th mat-sort-header="frequency_score" mat-header-cell *matHeaderCellDef style="width:80px; text-align: center;" (click)="sortData($event)" class="sticky-column">
                                            Frequency
                                        </th>
                                        <td mat-cell *matCellDef="let customer" style="width:80px; padding-right:28px; text-align: right;">
                                            {{ customer.frequency_score ?? 0 }}
                                        </td>
                                    </ng-container>
                            
                                    <!-- Monetary Score Column -->
                                    <ng-container matColumnDef="monetary_score">
                                        <th mat-sort-header="monetary_score" mat-header-cell *matHeaderCellDef style="width:80px; text-align: center;" (click)="sortData($event)"  class="sticky-column">
                                            Monetary
                                        </th>
                                        <td mat-cell *matCellDef="let customer" style="width:80px; padding-right:10px; text-align: right;">
                                            {{ customer.monetary_score ?? 0 }}
                                        </td>
                                    </ng-container>
                                          <!-- Purchase Amount Column -->
  
    <ng-container matColumnDef="purchase_amount">
        <th mat-sort-header="purchase_amount" [attr.rowspan]="2" mat-header-cell *matHeaderCellDef class="frequency-header"  style="text-align: right;" (click)="sortData($event)" class="sticky-column">
                Total Spend
        </th>
        <td mat-cell *matCellDef="let customer" class="frequency-column">
            {{(customer.purchase_amount ?? 0 | currency: 'USD' : 'symbol' : '1.2-2') }}
            
        </td>
    </ng-container>

    <!-- Total Orders Column -->
    <ng-container matColumnDef="purchase_counter">
        <th mat-sort-header="purchase_counter" [attr.rowspan]="2" mat-header-cell *matHeaderCellDef class="frequency-header" style="text-align: right;" (click)="sortData($event)">
            Total Orders
        </th>
        <td mat-cell *matCellDef="let customer" class="frequency-column">
            {{ customer.purchase_counter ?? 0 }}
        </td>
    </ng-container>

    <!-- Rewards Column -->
    <ng-container matColumnDef="rewards">
        <th mat-sort-header="customer.id" mat-header-cell *matHeaderCellDef (click)="sortData($event)" [attr.rowspan]="2" class="reward-header">
            Rewards
        </th>
        <td mat-cell *matCellDef="let customer" class="reward-column">
            <ng-container *ngIf="customer.customer_reward?.length > 0">
                <mat-icon>done</mat-icon>
            </ng-container>
        </td>
    </ng-container>
    <ng-container matColumnDef="action" stickyEnd>
        <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2"> </th>
        <td mat-cell class="row-menu-btn" style="width:50px" *matCellDef="let customer">
            <a mat-icon-button class="row-menu-btn" [matMenuTriggerFor]="rowMenu"
                (click)="onRowSelected(customer);$event.stopPropagation();">
                <mat-icon>more_vert</mat-icon>
            </a>
            <mat-menu #rowMenu>
                @if(permission.can_edit){ 
                <button mat-menu-item (click)="onEdit(customer)">Edit</button>
                }@else{
                    <button mat-menu-item (click)="onEdit(customer)">View</button>
 
                }
          
                <button mat-menu-item (click)="onRedirect(customer);">Orders</button>
            </mat-menu>
        </td>
    </ng-container>
                            
                                    <!-- Header Rows -->
                                    <tr mat-header-row *matHeaderRowDef="['selectMenu', 'select', 'item', 'customer', 'customer_type', 'mem_since', 'scoreGroup', 'purchase_amount', 'purchase_counter', 'rewards', 'action']; sticky: true"></tr>
                                    <tr mat-header-row *matHeaderRowDef="['recency_score', 'frequency_score', 'monetary_score'];sticky: true"></tr>
                            
                                    <!-- Data Rows -->
                                    <tr mat-row class="table-element-row" *matRowDef="let customer; columns: displayedColumns;"
                                        (click)="onRowSelected(customer)"
                                        (dblclick)="onEdit(customer)"
                                        [ngClass]="{'row-selected' : customer.id === selectedCustomer?.id}">
                                    </tr>
                                </table>
                            </section>
                            
                <!-- </ng-container> -->


                <!-- <ng-container *ngIf="isHandset">
                        <div class="info-container">
                                <app-customer-list-item *ngFor="let info of customerList" (edit)="onCustomerEdit($event)"
                                        [customerInfo]="info" class="info-item">
                                </app-customer-list-item>
                        </div>
                        <div>
                                <app-simple-paginator *ngIf="pagingData.length>pagingData.pageSize"
                                        [pagingData]="pagingData" (page)="onePageEvent($event)"></app-simple-paginator>
                        </div>

                </ng-container> -->
                <!-- </ng-container> -->
                @if(pagingData.length<=0){ <div class="no-data-container">

                        <img src="assets/images/no-data.png">
                        <span class="no-data-info">No data found.</span>
        </div>
        }
        <!-- <div class="data-container ">
                <app-loader [show]="isLoading"></app-loader>
                    
                    </div> -->
</div>
<!-- <app-master-floating-menu [menu]="menuOption" (onAdd)="addClicked()"
                class="master-floating-menu"></app-master-floating-menu> -->
</div>

<app-customer-summary [customer]="selectedCustomer" (onEdit)="onEdit($event)"
        (onDelete)="onDeleteClick($event)"></app-customer-summary>