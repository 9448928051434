<app-reward-menu> </app-reward-menu>
<div class="reward-component-container  page-root-container">
  <div class="page-header">
    <!-- <div class="page-title">Promotion</div> -->
    <div class="page-title">
      @if(rewardType==="1"){
      <img style="height: 24px;" src="assets/images/menu/svg/promotion.svg">
      }
      @if(rewardType==="2"){
        <img style="height: 24px;" src="assets/images/menu/svg/reward-category.svg">

      }

      @if(rewardType==="1"){
        <span>Offer</span>
      }
      @if(rewardType==="2"){
        <span>Reward</span>
      }
      <!-- <span>Promotion</span> -->
    </div>
    <div class="page-actions">
     @if(permission.can_add){
      <button mat-button color="primary" class="btn-add" (click)="addClicked()">
        <div style="display: flex; gap: 5px; align-items: center;">
          <img style="height: 16px;" src="assets/images/icons/plus.svg"> <span>New</span>
        </div>
      </button>
    }
    @if(permission.can_export){

      <button mat-button color="accent" class="btn-export" (click)="exportClicked()" [disabled]="IsDownloading">
        <img class="download-icon"
          [src]="IsDownloading ? 'assets/images/animated/download/download-anim.svg' : 'assets/images/animated/download/download-no-anim.svg'">
        Export
      </button>
    }
    </div>
  </div>

  <div class="data-container">
    <app-loader [show]="isLoading"></app-loader>

    <!-- <app-search-filter #searchFilter [length]="pagingData.length" [pageSize]="pagingData.pageSize"
      [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageIndex]="pagingData.pageIndex" [filter]="filter"
      (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true">
    </app-search-filter> -->
    <app-search-filter #searchFilter [pagingData]="pagingData" [pageSizeOptions]="[5,10,20,50,100]" [filter]="filter"
      (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true" [module]='"reward"'>
    </app-search-filter>

    <section tabindex="0" class="table-container">
      <table matSort mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef (click)="sortData($event)" class="item-header">#</th>
          <td mat-cell *matCellDef="let element; let i = index" class="item-cloum">{{
            (pagingData.pageIndex*pagingData.pageSize) + i + 1 }}</td>
        </ng-container>
        <ng-container matColumnDef="code">
          <th mat-sort-header="code" mat-header-cell *matHeaderCellDef (click)="sortData($event)">
            Code
          </th>
          <td mat-cell *matCellDef="let item">{{ item.code }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-sort-header="name" mat-header-cell *matHeaderCellDef (click)="sortData($event)">
            Name
          </th>
          <td mat-cell *matCellDef="let item">{{ item.name }}</td>
        </ng-container>

        <ng-container matColumnDef="crm_reward_category_id">
          <th mat-sort-header="crm_reward_category_id" mat-header-cell *matHeaderCellDef (click)="sortData($event)"
            class="category-name-header">
            Category
          </th>
          <td mat-cell *matCellDef="let item" class="category-name-column">{{ item.category_name }}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-sort-header="status" mat-header-cell *matHeaderCellDef (click)="sortData($event)"
            class="status-header">
            Status
          </th>
          <td mat-cell *matCellDef="let item" class="status-column">
            <div class="reward-active-status">
              @if(item.status === 1){
              <div class="status active">
                Published <span></span>
              </div>
              }@else if(item.status === 2){
              <div class="status expired">
                Expired
              </div>
              }
              @else{
              <div class="status unpublished">
                Unpublished
              </div>
              }
            </div>
          </td>
        </ng-container>
        <!-- <ng-container matColumnDef="reward_eligibility">
          <th mat-sort-header="reward_eligibility" mat-header-cell *matHeaderCellDef (click)="sortData($event)"
            class="target-header">
            Type
          </th>
          <td mat-cell *matCellDef="let item" class="target-column">
            <div class="reward-active-status">
              @if(item.reward_eligibility === 1){
              <div class="status offer">
                Offer <span></span>
              </div>
              }@else if(item.reward_eligibility === 2){
              <div class="status reward">
                Reward <span></span>
              </div>

              }
              @else{

              }
            </div>
          </td>
        </ng-container> -->

        <ng-container matColumnDef="published_on">
          <th mat-sort-header="published_on" mat-header-cell *matHeaderCellDef (click)="sortData($event)"
            class="start-date-header">
            Start Date
          </th>
          <td mat-cell *matCellDef="let item" class="start-date-column">
            <!-- {{ getUpdatedDate(item) }} -->
            {{ item.published_on | date: dateFormat }}

          </td>
        </ng-container>
        <ng-container matColumnDef="valid_until">
          <th mat-sort-header="valid_until" mat-header-cell *matHeaderCellDef (click)="sortData($event)"
            class="end-date-header">
            End Date
          </th>
          <td mat-cell *matCellDef="let item" class="end-date-column">
            @if(item.valid_for!= 0){
            {{ item.valid_until | date: dateFormat }}


            }
            @else{

            }
          </td>
        </ng-container>

        <ng-container matColumnDef="action" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell class="row-menu-btn" style="width: 50px" *matCellDef="let item">
         

            <a mat-icon-button class="row-menu-btn" [matMenuTriggerFor]="rowMenu"
              (click)="onRowSelected(item); $event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </a>
          

            <mat-menu #rowMenu let item>
              @if(permission.can_delete){
               
              <button mat-menu-item (click)="onDelete(item)" *ngIf="item.status === 0">
                Delete
              </button>
            }
            
            @if(permission.can_edit){

              <button mat-menu-item (click)="onEdit(item)">Edit</button>
            }@else{
              <button mat-menu-item (click)="onEdit(item)">View</button>

            }
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row class="table-element-row" *matRowDef="let item; columns: displayedColumns"
          (click)="onRowSelected(item)"     (dblclick)="onEdit(item)"
          [ngClass]="{ 'row-selected': item.id === selectedItem?.id }"></tr>
      </table>
    </section>
    @if(pagingData.length<=0){ 
      <div class="no-data-container">
          <img src="assets/images/no-data.png" />
          <span class="no-data-info">No data found. Please remove or redefine the filters if any.</span>
      </div>
      }
  </div>
</div>
<app-reward-summary [reward]="selectedItem" (onEdit)="onEdit($event)"
  (onDelete)="onDelete($event)"></app-reward-summary>