<div class="edit-page-root-container customer-edit-root-container">
    <div class="page-content">
        <div class="page-header">
            <button mat-button class="nav-button" (click)="navBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="title">
                {{title}}
            </div>
            <div class="action-btn-container">
                @if(permission.can_edit){ 
                <button mat-flat-button class="action-btn btn-discard" (click)="navBack()">DISCARD</button>
                <button mat-flat-button class="action-btn btn-save" (click)="save()">SAVE</button>
                }
            </div>
        </div>
        <div class="page-details ">
            <form class="edit-form" [formGroup]="editForm">
                <div class="detail-section">
                    <div class="content-container">

                        <div class="form-content-container">
                            <div class="input-group">
                                <div class="input-box-left">
                                    <mat-form-field class="title">
                                        <mat-label>Title</mat-label>
                                        <input matInput formControlName="title" [attr.maxlength]="10"
                                            pattern="[a-zA-Z\s]*">
                                        <mat-error
                                            *ngIf="editForm.controls['title'].hasError('required') && !editForm.controls['title'].value">Title
                                            should be entered</mat-error>


                                    </mat-form-field>
                                    <mat-form-field class="first_name">
                                        <mat-label>First Name</mat-label>
                                        <input matInput formControlName="first_name" [attr.maxlength]="50">
                                        <mat-error
                                            *ngIf="editForm.controls['first_name'].hasError('required') && !editForm.controls['first_name'].value">First
                                            Name should be entered</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="input-box-right">
                               
                                    <mat-form-field class="last_name">
                                        <mat-label>Last Name</mat-label>
                                        <input matInput formControlName="last_name" [attr.maxlength]="50" pattern="[a-zA-Z\s]*">
                                        <mat-error
                                            *ngIf="editForm.controls['last_name'].hasError('required') && !editForm.controls['last_name'].value">Last
                                            Name should be entered</mat-error>
        
                                    </mat-form-field>
                                </div>
                            </div>
                        
                            <!-- Add gender radio buttons here -->
                            <div style="display:flex; gap:20px;">
                                <div class="customer-type-select">
                                    <mat-form-field>
                                        <mat-label style="margin-top:15px;">Customer Type</mat-label>
                                        <mat-select formControlName="customer_type">
                                            <mat-option [value]=0>Social </mat-option>
                                            <mat-option [value]=1>Registered </mat-option>
                                            <mat-option [value]=2>Guest</mat-option>
                                        </mat-select>
                                        <mat-error
                                        *ngIf="editForm.controls['customer_type'].hasError('required') && !editForm.controls['customer_type'].value">Please Choose 
                                Customer Type</mat-error>
                                    </mat-form-field>
                                </div>
                            <div class="gender-radio">
                                <mat-label>Gender</mat-label>
                              
                                <mat-radio-group formControlName="gender">
                                    <mat-radio-button value="m">Male</mat-radio-button>
                                    <mat-radio-button value="f">Female</mat-radio-button>
                                </mat-radio-group>
                            </div>
                    
                        </div>
                            
                        </div>




                    </div>
                </div>
                <div class="detail-section">
                    <div class="group-elements">
                        <div class="section-detail">
                            <div class="input-left">
                                <!-- <mat-form-field class="address section-rows">
                                    <mat-label>Address</mat-label>
                                    <textarea matInput formControlName="address"></textarea>
                                    <mat-error *ngIf="editForm.controls['address'].hasError('maxlength')">Maximum length is
                                        250.</mat-error>
                                </mat-form-field> -->
                                <mat-form-field [class]="getFormFieldClass()">
                                    <mat-label>Email</mat-label>
                                    @if(customerInfo.id !== 0 && customerInfo.id !== undefined){
                                    <input matInput formControlName="email" [readonly]="!isEmailEditable" type="email">
                                    }
                                    @else{
                                    <input matInput formControlName="email" type="email">
                                    }

                                  <mat-error *ngIf="editForm.controls['email'].hasError('email')">Please enter a valid email address</mat-error>

                                </mat-form-field>
                                @if(permission.can_edit || permission.can_add){ 
                                <span [ngStyle]="{'display': customerInfo.id == undefined ? 'none' : 'inline-block'}">
                                    <button mat-button *ngIf="isEmailEditable" (click)="updateEmail()"
                                        class="action-btn btn-browse">Update</button>
                                    <button mat-button *ngIf="!isEmailEditable" (click)="toggleEmailEdit()"
                                        class="action-btn btn-browse">Change Email</button>
                                    <button mat-button *ngIf="isEmailEditable" (click)="toggleEmailEdit()"
                                        class="action-btn btn-cancel" style="margin-left:10px;">Cancel</button>

                                </span>
                            }
                            </div>
                            <div class="input-right">
                                <mat-form-field class="city section-rows">
                                    <mat-label>City</mat-label>
                                    <input matInput formControlName="city" [attr.maxlength]="50">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="section-detail">
                            <div class="input-left">
                                <mat-form-field class="state section-rows">
                                    <mat-label>State</mat-label>
                                    <input matInput formControlName="state" [attr.maxlength]="50">
                                </mat-form-field>

                            </div>
                            <div class="input-right">
                                <mat-form-field class="country section-rows">
                                    <mat-label>Country</mat-label>
                                    <input matInput formControlName="country" [attr.maxlength]="50">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="section-detail">
                            <div class="input-left">
                                <mat-form-field class="post_code section-rows">
                                    <mat-label>Post Code</mat-label>
                                    <input matInput formControlName="post_code" [attr.maxlength]="50">
                                </mat-form-field>
                            </div>
                            <div class="input-right">
                                <mat-form-field class="phone_no section-rows">
                                    <mat-label>Phone no</mat-label>
                                    <input matInput formControlName="phone_number" type="text" [attr.maxlength]="50">
                                    <mat-error *ngIf="editForm.controls['phone_number'].hasError('required')">Phone Number should be entered</mat-error>
                                    <mat-error *ngIf="editForm.controls['phone_number'].hasError('pattern')">Please enter a valid phone number</mat-error>
                                  </mat-form-field>
                                  
                            </div>
                        </div>
                        <div>
                            <!--                            
                              <mat-form-field [class]="getFormFieldClass()">
                                <mat-label>Email</mat-label>
                                <input matInput formControlName="email" [readonly]="!isEmailEditable">
                 
                              </mat-form-field>
                              <span [ngStyle]="{'display': customerInfo.id == undefined ? 'none' : 'inline-block'}">
                                <button mat-button *ngIf="isEmailEditable" (click)="updateEmail()" class="action-btn btn-browse">Update</button>
                                <button mat-button *ngIf="!isEmailEditable" (click)="toggleEmailEdit()" class="action-btn btn-browse">Change Email</button>
                                <button mat-button *ngIf="isEmailEditable" (click)="toggleEmailEdit()" class="action-btn btn-cancel" style="margin-left:10px;">Cancel</button>

                              </span> -->


                            <mat-form-field class="address section-rows">
                                <mat-label>Address</mat-label>
                                <textarea matInput formControlName="address" [attr.maxlength]="250"></textarea>

                            </mat-form-field>


                        </div>
                    </div>
                </div>
                <div class="detail-section">
                    <div><span><strong>Date of Joining</strong></span></div>
                    <div class="select-box-section" style="width:100%">
                        <mat-form-field class="select-box">
                            <mat-label>DD</mat-label>
                            <mat-select formControlName="doj_day">
                                @for (jday of dojDaysOfMonth; track jday.value) {
                                <mat-option [value]="jday.value">{{ jday.viewValues
                                    }}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="select-box">
                            <mat-label>MM</mat-label>
                            <mat-select formControlName="doj_month" style="width:80px;;">
                                @for (jmonth of jmonths; track jmonth.value)
                                {
                                <mat-option [value]="jmonth.value">{{ jmonth.viewValues
                                    }}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="select-box">
                            <mat-label>YYYY</mat-label>
                            <mat-select formControlName="doj_year">
                                @for (jyear of yearsDoj; track jyear)
                                {
                                <mat-option [value]="jyear">{{ jyear }}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div><span><strong>Date of Birth</strong></span></div>
                    <div class="select-box-section">
                        <mat-form-field class="select-box">
                            <mat-label>DD</mat-label>
                            <mat-select formControlName="dob_day">
                                @for (day of dobDaysOfMonth; track day.value)
                                {
                                <mat-option [value]="day.value">
                                    {{ day.viewValue }}
                                </mat-option>
                                }
                            </mat-select>

                        </mat-form-field>

                        <mat-form-field class="select-box">
                            <mat-label>MM</mat-label>
                            <mat-select formControlName="dob_month">
                                @for (month of dobMonths; track month.value)
                                {
                                <mat-option [value]="month.value">{{ month.viewValue
                                    }}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="select-box">
                            <mat-label>YYYY</mat-label>
                            <mat-select formControlName="dob_year">
                                @for (year of yearsDob; track year)
                                {
                                <mat-option [value]="year">{{ year }}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="detail-section" style="margin-bottom:30px;">
                    <div class="status-toggle-options">
                    <div class="status-toggle-container">
                        <mat-slide-toggle formControlName="is_subscribed" class="status-toggle"></mat-slide-toggle>
                        <span class="status-toggle-title">Subscribe for promotions</span>

                    </div>
                   
                    <div class="status-toggle-container">
                        <mat-slide-toggle formControlName="is_valid" class="status-toggle"></mat-slide-toggle>
                        <span class="status-toggle-title">Active</span>

                    </div>
                </div>
                </div>

            </form>
        </div>
    </div>
</div>