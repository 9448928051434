import { Component, Inject } from '@angular/core';
import { SnackBarService } from '../../../snackbar/snackbar.service';
import { FilterService } from '../../filter.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ColumnFilter, CustomFilter } from 'src/app/common/models/request-data.model';
import { FilterListItemModel, SearchParam } from '../../search-filter.component';
import { SearchFilterSaveDialogComponent } from '../search-filter-save-dialog/search-filter-save-dialog.component';
import { SearchFilterLoadDialogComponent } from '../search-filter-load-dialog/search-filter-load-dialog.component';
import { SnackBarData } from '../../../snackbar/model/snackbar.model';
import { AppConstants } from 'src/app/common/app.constants';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../../confirm-dialog/confirm-dialog.component';
import { CustomerService } from 'src/app/pages/customer/customer.service';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
  standalone: false
})
export class FilterDialogComponent {
  showCustFilter: boolean = false;
  enableCustFilter: boolean = false;
  selectedColumnFilter: ColumnFilter[] = [];
  selectedFilters: FilterListItemModel[][] = [];
  selectedCustFilter: { sql: string } = { sql: "" };
  enableRun: boolean = false;
  searchInfo: any;
  filter?: FilterListItemModel[] = [];
  module: any;
  filterInfo: string = "0";
  user: any;
  columnFilter: any;

  constructor(public dialogRef: MatDialogRef<FilterDialogComponent>,
    private filterService: FilterService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBarService: SnackBarService,
    private dialog: MatDialog,
    private customerService: CustomerService) {

    this.module = data.module;
    this.filter = data.filterOptions;
    this.enableRun = (data.enableRun != undefined) ? data.enableRun : false;
    this.enableCustFilter = data.enableCustFilter;
    this.selectedCustFilter = (data.custFilter != undefined) ? data.custFilter : { sql: "" };
    this.setFilter(data.columnFilter)
    let sesUser = sessionStorage.getItem(AppConstants.SESSION_VAR_USER);
    this.user = (sesUser !== null) ? JSON.parse(sesUser) : { id: 0 };
    this.searchInfo = { id: 0, name: "", isFavorite: 0, module: this.module, filter: undefined, user_id: this.user.id };

  }


  saveClicked(): void {
    // Check if customer search is valid
    const isCustSearchValid = this.selectedCustFilter && this.selectedCustFilter.sql.trim() !== "";

    // Check if all other column filters are empty
    const areOtherFiltersEmpty = this.selectedColumnFilter.every(filter =>
      filter.column === 'custSearch' ||
      filter.value === null ||
      filter.value === undefined ||
      (typeof filter.value === 'string' && filter.value.trim() === "")
    );

    // Remove invalid filters only if custSearch is valid and other filters are empty
    if (isCustSearchValid && areOtherFiltersEmpty) {
      this.removeInvalidFilters();
    }

    this.searchInfo.module = this.module;
    this.searchInfo.filter = { columnFilter: this.selectedColumnFilter, custFilter: this.selectedCustFilter };
    const dialogRef = this.dialog.open(SearchFilterSaveDialogComponent, {
      width: '90%',
      maxWidth: '500px',
      height: '295px',
      data: this.searchInfo
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.searchInfo = result;
      } else {
        console.log('filter canceled');
      }
    });
  }

  /**
   * Remove all filters from selected filter array.
   */
  onFilterReset(): void {
    this.selectedFilters.length = 0;
    this.selectedColumnFilter.length = 0;
    this.selectedCustFilter = { sql: "" };
    this.searchInfo = { id: 0, name: "", isFavorite: 0, module: this.module, filter: undefined, user_id: this.user.id };

  }

  /**
   * Add new filter 
   */
  onFilterAdd(): void {

    let newFilter = JSON.parse(JSON.stringify(this.filter));
    this.selectedFilters.push(newFilter);
    this.selectedColumnFilter.push({ column: "", operator: "", value: "" })
  }


  /**
   * 
   * @param filter Remove the filter from selcted filters
   */
  onFilterRemove(index: any): void {
    this.selectedFilters.splice(index, 1);
    this.selectedColumnFilter.splice(index, 1);

  }

  /**
   * Emit the event with the selected filters
   */
  onFilterApply(): void {
    const isCustSearchValid = this.selectedCustFilter && this.selectedCustFilter.sql.trim() !== "";

    // Check if all other filters are empty
    const areOtherFiltersEmpty = this.selectedColumnFilter.every(filter =>
      filter.column === 'customSearch' ||
      filter.value === null ||
      filter.value === undefined ||
      (typeof filter.value === 'string' && filter.value.trim() === "")
    );

    // Remove filters only if customer search is valid and other filters are empty
    if (isCustSearchValid && areOtherFiltersEmpty) {
      this.removeInvalidFilters();
    }

    this.filterInfo = this.selectedColumnFilter.length + "";
    this.dialogRef.close({ columnFilter: this.selectedColumnFilter, custFilter: this.selectedCustFilter });
  }





  removeInvalidFilters(): void {
    if (this.selectedColumnFilter && Array.isArray(this.selectedColumnFilter)) {
      this.selectedColumnFilter = this.selectedColumnFilter.filter(
        (filter: { column?: string, operator?: string, value?: any }) =>
          filter.column &&
          filter.operator &&
          filter.value !== null &&
          filter.value !== undefined &&
          (typeof filter.value === 'number' ||
            (typeof filter.value === 'string' && filter.value.trim() !== ""))
      );
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }


  /**
     * Loads predefined filter from saved list
     */
  onFilterLoad(): void {

    let searchInfo = { search: { filters: { simpleSearch: [], advSearch: [{ column: "module", operator: "=", value: this.module }, { column: "user_id", operator: "=", value: this.user.id }], customSearch: { sql: "" }, scope: { limit: 50, offset: 0 } }, sort: [] } }
    this.filterService.getFilter(searchInfo).subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          let searchFilter = response.data.filters;
          const dialogRef = this.dialog.open(SearchFilterLoadDialogComponent, {
            width: '90%',
            maxWidth: '500px',
            height: '500px',
            data: searchFilter
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.searchInfo = result;
              this.searchInfo.filter = JSON.parse(result.filter);
              this.setFilterExt(this.searchInfo.filter);

            } else {
              console.log('filter canceled');
            }
          });


        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        // handle error
        let snackBarData: SnackBarData = {
          message: 'Failed to update. Connection to the server failed',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }
  /**
   * Set the filters from pre loaded json
   */
  setFilter(columFilters: ColumnFilter[], apply?: boolean): void {
    this.selectedFilters.length = 0;
    this.selectedColumnFilter.length = 0;
    columFilters.forEach(f => {
      let newFilter = JSON.parse(JSON.stringify(this.filter));
      this.selectedFilters.push(newFilter);
      // this.selectedColumnFilter.push({ column: f.column, operator: f.operator, value: f.value,type: f.type })
      this.selectedColumnFilter.push(f)

    });
    if (this.selectedColumnFilter == undefined || this.selectedColumnFilter.length == 0) {
      this.onFilterAdd();
    }
    if (apply) {
      this.onFilterApply();
    }
  }
  setFilterExt(filter: { columnFilter: ColumnFilter[], custFilter: CustomFilter }, apply?: boolean) {
    this.selectedFilters.length = 0;
    this.selectedColumnFilter.length = 0;
    if (filter.columnFilter != undefined && filter.columnFilter.length > 0) {
      filter.columnFilter.forEach(f => {
        let newFilter = JSON.parse(JSON.stringify(this.filter));
        this.selectedFilters.push(newFilter);
        // this.selectedColumnFilter.push({ column: f.column, operator: f.operator, value: f.value,type: f.type })
        this.selectedColumnFilter.push(f)

      });
    }
    this.selectedCustFilter = (filter.custFilter != undefined) ? filter.custFilter : { sql: "" };
    if (this.selectedColumnFilter == undefined || this.selectedColumnFilter.length == 0) {
      this.onFilterAdd();
    }
    if (apply) {
      this.onFilterApply();
    }
  }
  canShowCustomFilter(): boolean {

    return (this.showCustFilter || (this.selectedCustFilter != undefined && this.selectedCustFilter.sql.length > 0));
  }
  showHideCustomFilter(): void {
    this.showCustFilter = !this.showCustFilter;
  }
  /**
   * Run the filter
   */
  onFilterRun(): void {
       // Check if customer search is valid
       const isCustSearchValid = this.selectedCustFilter && this.selectedCustFilter.sql.trim() !== "";

       // Check if all other column filters are empty
       const areOtherFiltersEmpty = this.selectedColumnFilter.every(filter =>
         filter.column === 'customSearch' ||
         filter.value === null ||
         filter.value === undefined ||
         (typeof filter.value === 'string' && filter.value.trim() === "")
       );
   
       // Remove invalid filters only if custSearch is valid and other filters are empty
       if (isCustSearchValid && areOtherFiltersEmpty) {
         this.removeInvalidFilters();
       }
    let searchFilterData = { search: { filters: { simpleSearch: [], advSearch: this.selectedColumnFilter, customSearch: this.selectedCustFilter,scope: { limit: 0, offset: 0 } }, sort: [] } };

    this.customerService.getList(searchFilterData).subscribe({
      next: (response) => {
        if (response.status === "SUCCESS") {
          let custCount = response.data.total_count;
          const message = `Your filter return ` + custCount + ` customers.`;
          const dialogData = new ConfirmDialogModel("Filter Result", message);
          dialogData.showNoButton = false;
          dialogData.yesButtonCaption = 'OK';
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: "400px",
            data: dialogData
          });
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed to run filter",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: "Failed to run filter",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      }
    });

  }

}
